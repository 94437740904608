* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Lexend Deca';font-size: 17px;
    cursor: none !important;

  }

  a, a:hover {
    cursor: none !important;
  }

  button {
    cursor: none !important;
  }

  html {
    scroll-behavior: smooth;
    scroll-padding-top: 30px;
  }
  
header {
    background-image: url("../public/GreenBG2.jpg");
    background-size: cover ;
    background-position: 100% 80%;
    height: 100vh;
    margin: auto -16px;
    padding: 16px;

    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}
.header-text{
    margin-top: auto;
}

.headerdiv {
    margin:0 auto;
    width: 95%
}
.fullbody {
    padding: 0px !important;
}
header h1 {
    font-size: 5vw;
    text-align: center;
}

header h2 {
    text-align: center;
    font-size: 2rem;
}
header img {
    width: 22vw;
    height: 22vw;
}

.headersmall {
    background-color: #AEC3AE;
    margin: auto -16px;
    color: white;
}
.toppadding {
margin-top: 35px;
}

.navbar {
    transform: translateY(-100%);
    transition: transform 0.3s ease;
  }
  
  .navbar.scrolled {
    transform: translateY(0);
  }
  
nav{
    background-color: rgb(62, 61, 61);
}

nav .navbar-nav li a{
    color: white !important;
    font-size: large;
    }

.smallnav {
    padding-top: 2vh;
    padding-bottom: 2vh;
    margin-bottom: 3vh;
}
.centered {
    text-align: center;
}

.rightalign {
    text-align: right;
}

.resume {
    text-align: center;
    padding: 32px;
}
h2{
    text-align: center;
}

body {
    background: #94A684;
    background: linear-gradient(#94A684, #AEC3AE, #E4E4D0, #FFEEF4);
  }

.basicpadding {
    padding-bottom: 50px;
}
cards {
    padding: 32px;
}

.banner {
    background-color: #94A684;
    text-align: center;
    margin: auto -16px;
    padding: 32px;
    bottom: 0;
    height: 2.5rem;
}

.contact {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.footer {
    background-color: #FFEEF4;
    text-align: center;
  }
  
.footer p {
    color: #ccc;
}

.footerText{
    font-size: 1.4rem;
}

.card-img-overlay h3 {
    text-align: center;
    bottom: 0;
    position: absolute;
}

.defaultoutlinebutton {
    padding: 16px;
}

#aboutpic {
    width: 100%;
    height: auto; /* To maintain the aspect ratio */
}


.dictionary {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 4rem auto;
    max-width: 90%;
  }

  .cardButton {
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
  }
 .comicstrip {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
    padding-bottom: 8vh;
 }
 #comicimg {
    max-width: 80%;
 }
  /* Extra Small Devices (Phones) */
@media screen and (max-width: 575.98px) {
    /* Styles for extra small devices (phones) */
    header img {
        width: 60vw;
        height: 60vw;
        margin-bottom: 5px;
    }
    header h1 {
        font-size: 12vw;
    }
    header h2 {
        font-size: 6.5vw;
    }

    #aboutpic {
        padding-bottom: 5vh;
    }
    
    #comicimg {
        max-width: 90%;
     }
    
  }
  
  /* Small Devices (Tablets) */
  @media screen and (min-width: 576px) and (max-width: 767.98px) {
    /* Styles for small devices (tablets) */
  }
  
  /* Medium Devices (Laptops and Desktops) */
  @media screen and (min-width: 768px) and (max-width: 991.98px) {
    /* Styles for medium devices (laptops and desktops) */
    #aboutpic {
        width: 70%;
        padding-bottom: 5vh;
    }

  }
  
  /* Large Devices (Large Desktops) */
  @media screen and (min-width: 992px) and (max-width: 1199.98px) {
    /* Styles for large devices (large desktops) */
  }
  
  /* Extra Large Devices (Extra Large Desktops) */
  @media screen and (min-width: 1200px) {
    /* Styles for extra large devices (extra large desktops) */
    #comicimg {
        max-width: 70%;
     }
  }
  